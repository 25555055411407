<script setup lang="js">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import useModal from '@/stores/modal.store';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import TableToRegistryModal from '@/components/modals/Autonode/TableToRegistryModal.vue';
import { useAutonodeStore } from '@/stores/autonode.store';

const autonodeStore = useAutonodeStore();
const {
  selectedTableToRegistryIndex,
  custom_variables,
  tableToRegistries,
  autonodeRegistryList,
  variableList,
} = storeToRefs(autonodeStore);
const { activeModal, closeModal } = useModal();

const regNameGetter = computed(() => (registryId) => {
  if (!autonodeRegistryList.value?.length) return '';

  const table = autonodeRegistryList.value?.find((t) => t.id === registryId);

  if (!table) return 'Отсутствует переменная';

  return table.id;
});
const allVariables = computed(() => [...custom_variables.value, ...variableList.value]);

function getVariableTitle(variableName) {
  let variable = allVariables.value.find((v) => v.name == variableName);
  return variable?.title || '';
}

function openUpdateModal(index) {
  selectedTableToRegistryIndex.value = index;
  activeModal.value = 'editModal';
}

function openDeleteModal(index) {
  selectedTableToRegistryIndex.value = index;
  activeModal.value = 'deleteModal';
}

function deleteOutput() {
  tableToRegistries.value.splice(selectedTableToRegistryIndex.value, 1);
  closeModal();
}
</script>

<template>
  <div class="autoform-variables">
    <div class="d-flex justify-content-between align-items-center input-btns">
      <div class="add-btn" @click="activeModal = 'editModal'">
        <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
        Добавить таблицу для записи в реестр
      </div>
    </div>
    <div class="table-responsive">
      <table class="table gray-table">
        <thead>
          <tr>
            <th>Переменная</th>
            <th>Реестр</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(entity, index) in tableToRegistries" :key="index">
            <td>
              {{ getVariableTitle(entity.variable) }}
            </td>
            <td>
              {{ regNameGetter(entity.registry) }}
            </td>
            <td>
              <div class="table-buttons">
                <div class="table-button" @click="openUpdateModal(index)">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click="openDeleteModal(index)">
                  <span class="delite-ico" />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <DeleteModal
    v-if="activeModal === 'deleteModal'"
    :itemText="'эту строку'"
    @confirmDelete="deleteOutput()"
  />
  <TableToRegistryModal v-if="activeModal === 'editModal'" />
</template>
