<script setup lang="js">
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { useReturnStore } from '@/stores/return.store';
import { useRouteParams } from '@vueuse/router';
import { computed, onMounted } from 'vue';
import ReturnBpNodeCreateModal from '@/components/modals/ReturnBpNodeCreateModal.vue';
import ContextMenu from '@/components/ContextMenu.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');

const returnStore = useReturnStore();
const { activeModal, openModal, closeModal } = useModal();
const { returnBpNodes, currentReturnBpNode } = storeToRefs(returnStore);

onMounted(() => {
  returnStore.getReturnBusinessProcessNodes(projectId.value, bpId.value, nodeId.value);
});

function openDeleteModal(returnBpNode) {
  currentReturnBpNode.value = returnBpNode;
  activeModal.value = 'deleteModal';
}

function doDeleteReturn() {
  returnStore
    .deleteReturnBPNode(projectId.value, bpId.value, nodeId.value, currentReturnBpNode.value.id)
    .then(() => {
      closeModal();
      returnStore.getReturnBusinessProcessNodes(projectId.value, bpId.value, nodeId.value);
    });
}

const hasUnstableReturns = computed(() => false);
</script>

<template>
  <div class="bp-section-content_links">
    <RouterLink
      :to="{
        name: 'return',
        params: {
          projectId: projectId,
          bpId: bpId,
          nodeId: nodeId
        }
      }"
      class="bp-section-content_link"
      :class="{ 'input-fixation_circle': hasUnstableReturns }"
    >
      Возврат
    </RouterLink>
  </div>

  <div class="d-flex justify-content-between align-items-center input-btns">
    <div class="add-btn" @click="openModal('returnBpNodeCreateModal')">
      <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
      Добавить узел для возврата
    </div>
    <ReturnBpNodeCreateModal v-if="activeModal == 'returnBpNodeCreateModal'" />
  </div>

  <div class="table-responsive pb-5">
    <table class="table gray-table">
      <thead>
        <tr>
          <th>Наименование</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="returnBpNode in returnBpNodes"
          :key="returnBpNode.id"
          :class="{
            'fixation-row-style': !returnBpNode.stable
          }"
        >
          <td>
            <div class="d-flex align-items-center">
              <div v-if="!returnBpNode.stable" class="fixation-circle-style"></div>
              <span class="name ps-2">{{ returnBpNode.return_bp_node.current.node.name }}</span>
            </div>
          </td>
          <td>
            <ContextMenu :menuItems="['delete']" @delete="openDeleteModal(returnBpNode)" />
          </td>
        </tr>
      </tbody>
    </table>
    <DeleteModal
      v-if="activeModal === 'deleteModal'"
      :itemText="'этот возврат'"
      @confirmDelete="doDeleteReturn()"
    />
  </div>
</template>
