<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { useReturnStore } from '@/stores/return.store';

const returnStore = useReturnStore();
const { availableBpNodes, createReturnBpNodeRequest } = storeToRefs(returnStore);

const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { closeModal } = useModal();

const returnBpNode = ref({});
onMounted(() => {
  returnStore.getAvailableAncestorBusinessProcessNodes(projectId.value, bpId.value, nodeId.value);
});

function createReturnBpNode() {
  returnStore
    .createReturnBpNode(projectId.value, bpId.value, nodeId.value, returnBpNode.value)
    .then(() => {
      closeModal();
      returnStore.getReturnBusinessProcessNodes(projectId.value, bpId.value, nodeId.value);
    });
}

function isErrorField(fieldName) {
  if (createReturnBpNodeRequest.value.error?.errors) {
    return createReturnBpNodeRequest.value.error.errors[fieldName] || false;
  }
}
</script>

<template>
  <ModalTemplateSide
    @submit="createReturnBpNode"
    :disableButtons="createReturnBpNodeRequest.isLoading"
  >
    <template v-slot:title>Добавить узел для возврата</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Узел для возврата</label>
        <select
          v-model="returnBpNode"
          class="form-select"
          :class="{ error: isErrorField('return_bp_node_id') }"
        >
          <option v-for="bpNode in availableBpNodes" :key="bpNode.id" :value="bpNode.id">
            {{ bpNode.current.node.name }}
          </option>
        </select>
        <p v-if="isErrorField('return_bp_node_id')" class="error-mesage">
          <span v-for="error in isErrorField('return_bp_node_id')">{{ error }}</span>
        </p>
      </div>
    </template>
    <template v-slot:submit>Создать</template>
  </ModalTemplateSide>
</template>
