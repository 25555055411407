<script setup lang="js">
import { useAutonodeStore } from '@/stores/waitingAutonode.store';
import { useViewFieldStore } from '@/stores/viewField.store';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import useModal from '@/stores/modal.store';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import VariableCreateModal from '@/components/modals/WaitingAutonode/VariableCreateModal.vue';
import VariableUpdateModal from '@/components/modals/WaitingAutonode/VariableUpdateModal.vue';

const autonodeStore = useAutonodeStore();
const viewFieldStore = useViewFieldStore();
const projectStore = useProjectStore();
const { activeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { viewFieldList } = storeToRefs(viewFieldStore);
const { custom_variables, variableTypeList, selectedCustomVariableIndex, viewFieldId } = storeToRefs(autonodeStore);

function openUpdateModal(itemIndex) {
  selectedCustomVariableIndex.value = itemIndex;
  activeModal.value = "VariableUpdateModal";
}

function deleteVariable(itemIndex) {
  custom_variables.value.splice(itemIndex, 1);
}
function fieldName(field) {
  let fieldKeys = Object.keys(field);
  let title = {};
  if (fieldKeys.includes("input_catalog")) {
    title = field.input_catalog.catalog.title;
  }
  if (fieldKeys.includes("input_table")) {
    title = field.input_table.the_table.title;
  }
  if (fieldKeys.includes("input_static")) {
    title = field.input_static.static.title;
  }
  if (fieldKeys.includes("input_field")) {
    title = field.input_field.field.title;
  }
  return title[project.value.default_locale];
}

</script>

<template>
  <div class="autoform-variables">
    <div>
      <label>Просматриваемое поле</label>
      <select v-model="viewFieldId" class="form-select">
        <option :value="viewField.id" v-for="(viewField, rowIndex) in viewFieldList" :key="rowIndex">
          {{ fieldName(viewField) }}
        </option>
      </select>
    </div>
    <div class="d-flex justify-content-between align-items-center input-btns">
      <div class="add-btn" @click="activeModal = 'VariableCreateModal'">
        <img src="@/assets/img/plus-ico.svg" alt="plus-ico.svg" />
        Создать переменную
      </div>
    </div>
    <div class="table-responsive">
      <table class="table gray-table">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Тип</th>
            <th>Значение по умолчанию</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(variable, index) in custom_variables" :key="index">
            <td>
              {{ variable.title }}
            </td>
            <td>
              {{ variableTypeList[variable.type] }}
            </td>
            <td>
              {{ variable.default_value }}
            </td>
            <td>
              <div class="table-buttons">
                <div class="table-button" v-if="variable.type != 'array'" @click="openUpdateModal(index)">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click="deleteVariable(index)">
                  <span class="delite-ico" />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <DeleteModal v-if="activeModal === 'deleteModal'" :itemText="'эту переменную'" @confirmDelete="deleteVariable()" />
  <VariableCreateModal v-if="activeModal === 'VariableCreateModal'" />
  <VariableUpdateModal v-if="activeModal === 'VariableUpdateModal'" />
</template>
