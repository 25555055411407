import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';
import { useRouteParams } from '@vueuse/router';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useReturnStore = defineStore('return', () => {
  const axios = inject(AXIOS) || baseAxios;

  const getReturnBPNodeListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getAvailableReturnBPNodeListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createReturnBpNodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteReturnBpNodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const returnBpNodes = ref([]);
  const availableBpNodes = ref([]);
  const currentReturnBpNode = ref({});

  function getReturnBusinessProcessNodes(projectId, bpId, nodeId) {
    getReturnBPNodeListRequest.value = {};
    const req = getReturnBPNodeListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.returns({ projectId, bpId, nodeId }))
      .then((resp) => {
        returnBpNodes.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getAvailableAncestorBusinessProcessNodes(projectId, bpId, nodeId) {
    getAvailableReturnBPNodeListRequest.value = {};
    const req = getAvailableReturnBPNodeListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.returnAncestors({ projectId, bpId, nodeId }))
      .then((resp) => {
        availableBpNodes.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createReturnBpNode(projectId, bpId, nodeId, bpNodeId) {
    createReturnBpNodeRequest.value = {};
    const req = createReturnBpNodeRequest.value;
    req.isLoading = true;

    return axios
      .post(api.returns({ projectId, bpId, nodeId }), {
        return_bp_node_id: bpNodeId
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteReturnBPNode(projectId, bpId, nodeId, returnBpNodeId) {
    deleteReturnBpNodeRequest.value = {};
    const req = deleteReturnBpNodeRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.returnById({ projectId, bpId, nodeId, returnBpNodeId }))
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    getReturnBPNodeListRequest,
    getAvailableReturnBPNodeListRequest,
    returnBpNodes,
    availableBpNodes,
    createReturnBpNodeRequest,
    currentReturnBpNode,

    getReturnBusinessProcessNodes,
    getAvailableAncestorBusinessProcessNodes,
    createReturnBpNode,
    deleteReturnBPNode
  };
});
