<script setup lang="js">
import { ref, onMounted, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouteParams } from '@vueuse/router';

import { useAutonodeStore } from '@/stores/waitingAutonode.store';
import { useProjectStore } from '@/stores/project.store';
import useModal from '@/stores/modal.store';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';

const autonodeStore = useAutonodeStore();
const projectStore = useProjectStore();
const {
  selectedTableToRegistryIndex: regId,
  custom_variables,
  tableToRegistries,
  autonodeRegistryList,
  variableList,
} = storeToRefs(autonodeStore);
const { project } = storeToRefs(projectStore);
const { closeModal } = useModal();
const projectId = useRouteParams('projectId');

const variable = ref(null);
const registry_id = ref(null);
const columns = ref({});

const allVariables = computed(() => [...custom_variables.value, ...variableList.value]);
const selectedCustomVariable = computed(() => {
  return allVariables.value.find((cv) => cv.name == variable.value) || {};
});
const selectedRegistry = computed(() => {
  return autonodeRegistryList.value.find((r) => r.id == registry_id.value) || {};
});

function createTableToRegistry() {
  if (!selectedCustomVariable.value?.name || !selectedRegistry.value?.id) {
    return;
  }

  let registryObject = {};
  registryObject.variable = variable.value;
  registryObject.columns = columns.value;
  registryObject.registry = selectedRegistry.value.id;

  if (regId.value) {
    tableToRegistries.value[regId.value] = registryObject;
  } else {
    tableToRegistries.value.push(registryObject);
  }
  closeModal();
}
onMounted(() => {
  if (!project.value.locales) {
    projectStore.getProject(projectId.value);
  }
  if (tableToRegistries.value[regId.value]) {
    let registryObject = { ...tableToRegistries.value[regId.value] };
    variable.value = registryObject.variable;
    columns.value = registryObject.columns;
    registry_id.value = registryObject.registry;
  }
});
</script>

<template>
  <ModalTemplateSide @submit="createTableToRegistry">
    <template v-slot:title>Добавить таблицу для массовой записи в реестр</template>
    <template v-slot:body>
      <div class="mt-3">
        <label class="form-label">Реестр</label>
        <select v-model="registry_id" class="form-select">
          <option v-for="reg in autonodeRegistryList" :key="reg.id" :value="reg.id">
            {{ reg.id }}
          </option>
        </select>
      </div>
      <div class="mt-3">
        <label class="form-label">Переменная</label>
        <select v-model="variable" class="form-select">
          <template v-for="variable in allVariables" :key="variable.name">
            <option v-if="variable.type === 'array'" :value="variable.name">
              {{ variable.title }}
            </option>
          </template>
        </select>
      </div>

      <div class="mt-4" v-if="selectedRegistry?.id && selectedCustomVariable">
        <h5>
          Столбцы (выберите соответствующие значения из переменных массива для записи в столбцы
          реестра)
        </h5>
        <template v-for="col in selectedRegistry.columns" :key="col.id">
          <div class="mt-3" v-if="col.name !== 'id'">
            <label class="form-label">{{ selectedRegistry.id }} -> {{ col.description }}</label>
            <select v-model="columns[col.id]" class="form-select">
              <template v-for="arrCol in selectedCustomVariable.table_columns" :key="arrCol.name">
                <option :value="arrCol.name" v-if="arrCol.type === col.var_type">
                  {{ selectedCustomVariable.title }} -> {{ arrCol.title }}
                </option>
              </template>
            </select>
          </div>
        </template>
      </div>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
