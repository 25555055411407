import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useServiceGroupStore = defineStore('serviceGroup', () => {
  const axios = inject(AXIOS) || baseAxios;

  const searchName = ref('');
  const sortType = ref('1');
  const serviceGroup = ref({});
  const serviceGroupList = ref([]);
  const selectedServiceGroups = ref([]);
  const isSelectGroupAll = ref(false);

  const getServiceGroupListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createServiceGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteServiceGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateServiceGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getServiceGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getServiceGroupList(projectId) {
    getServiceGroupListRequest.value = {};
    const req = getServiceGroupListRequest.value;
    req.isLoading = true;
    serviceGroupList.value = [];
    return axios
      .get(api.serviceGroups({ projectId }))
      .then((resp) => {
        serviceGroupList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createServiceGroup(projectId, title) {
    createServiceGroupRequest.value = {};
    const req = createServiceGroupRequest.value;
    req.isLoading = true;

    return axios
      .post(api.serviceGroups({ projectId }), {
        title
      })
      .then(() => {
        getServiceGroupList(projectId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteServiceGroup(projectId, serviceGroupId) {
    deleteServiceGroupRequest.value = {};
    const req = deleteServiceGroupRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.serviceGroupById({ projectId, serviceGroupId }))
      .then(() => {
        getServiceGroupList(projectId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  function updateServiceGroup(projectId, serviceGroupId, title) {
    updateServiceGroupRequest.value = {};
    const req = updateServiceGroupRequest.value;
    req.isLoading = true;

    return axios
      .patch(api.serviceGroupById({ projectId, serviceGroupId }), { title })
      .then(() => {
        getServiceGroupList(projectId);
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getServiceGroup(serviceGroupId, projectId) {
    getServiceGroupRequest.value = {};
    const req = getServiceGroupRequest.value;
    req.isLoading = true;
    serviceGroup.value = {};
    return axios
      .get(api.serviceGroupById({ projectId, serviceGroupId }))
      .then((resp) => {
        serviceGroup.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
 
  return {
    getServiceGroupList,
    createServiceGroup,
    deleteServiceGroup,
    updateServiceGroup,
    getServiceGroup,

    getServiceGroupListRequest,
    createServiceGroupRequest,
    deleteServiceGroupRequest,
    updateServiceGroupRequest,
    getServiceGroupRequest,
    serviceGroupList,
    serviceGroup,
    selectedServiceGroups,
    isSelectGroupAll,
    searchName,
    sortType
  };
});
