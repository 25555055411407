import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useAutonodeStore = defineStore('autonode', () => {
  const axios = inject(AXIOS) || baseAxios;

  const autonode = ref({});
  const variableList = ref([]);
  const variableTypeList = ref({
    text: "text",
    double: 'double',
    boolean: 'boolean',
    identificator: 'identificator',
    date: 'date',
    line: 'line',
    point: 'point',
    polygon: 'polygon',
    geometry: 'geometry',
    document: 'document',
    array: 'array',
  });
  const outputVariableList = ref([]);
  const operationList = ref([]);
  const selectedCustomVariableIndex = ref(null);
  const selectedInstructionStep = ref(null);
  const instruction_steps = ref([]);
  const custom_variables = ref([]);
  const output_entities = ref([]);
  const tableToRegistries = ref([]);
  const direction_variable = ref(null);
  const direction_values = ref({});
  const selectedDirectionIndex = ref(null);
  const selectedOutputIndex = ref(null);
  const selectedTableToRegistryIndex = ref(null);
  const newUuid = ref(null);
  const autonodeRegistryList = ref([]);

  const getVariableListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getOutputVariableListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getOperationListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateAutonodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const generateUuidRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getAutonodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getAutonodeRegistriesRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });


  function getVariableList(projectId, bpId, nodeId) {
    getVariableListRequest.value = {};
    const req = getVariableListRequest.value;
    req.isLoading = true;
    return axios
      .get(api.autonodeVariables({ projectId, bpId, nodeId }))
      .then((resp) => {
        variableList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getOutputVariableList(projectId, bpId, nodeId) {
    getOutputVariableListRequest.value = {};
    const req = getOutputVariableListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.autonodeOutputVariables({ projectId, bpId, nodeId }))
      .then((resp) => {
        outputVariableList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getOperationList(projectId, bpId, nodeId) {
    getOperationListRequest.value = {};
    const req = getOperationListRequest.value;
    req.isLoading = true;
    return axios
      .get(api.autonodeOperations({ projectId, bpId, nodeId }))
      .then((resp) => {
        operationList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateAutonode(
    projectId,
    bpId,
    nodeId,
    instruction_steps,
    custom_variables,
    output_entities
  ) {
    updateAutonodeRequest.value = {};
    const req = updateAutonodeRequest.value;
    req.isLoading = true;
    return axios
      .post(api.autonode({ projectId, bpId, nodeId }), {
        instruction_steps,
        custom_variables,
        output_entities,
        registries: tableToRegistries.value,
        directions: {
          direction_variable: direction_variable.value,
          direction_values: direction_values.value,
        }
      })
      .then(() => {

      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getAutonode(
    projectId,
    bpId,
    nodeId
  ) {
    getAutonodeRequest.value = {};
    const req = getAutonodeRequest.value;
    req.isLoading = true;
    return axios
      .get(api.autonode({ projectId, bpId, nodeId }))
      .then((resp) => {
        autonode.value = resp.data;
        direction_variable.value = resp.data?.directions?.direction_variable;
        direction_values.value = resp.data?.directions?.direction_values ?? {};
        tableToRegistries.value = resp.data?.registries ?? [];
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function generateUuid() {
    generateUuidRequest.value = {};
    const req = generateUuidRequest.value;
    req.isLoading = true;
    return axios
      .get(api.uuid())
      .then((resp) => {
        newUuid.value = resp.data;
        return resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getAutonodeRegistryList(projectId, bpId, nodeId) {
    getAutonodeRegistriesRequest.value = {};
    const req = getAutonodeRegistriesRequest.value;
    req.isLoading = true;

    return axios
      .get(api.autonodeRegistries({ projectId, bpId, nodeId }))
      .then((resp) => {
        autonodeRegistryList.value = resp.data;
        return resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    getOperationList,
    getVariableList,
    getOutputVariableList,
    getAutonodeRegistryList,
    updateAutonode,
    generateUuid,
    getAutonode,
    operationList,
    variableList,
    variableTypeList,
    outputVariableList,
    autonodeRegistryList,
    selectedCustomVariableIndex,
    autonode,
    instruction_steps,
    custom_variables,
    output_entities,
    tableToRegistries,
    direction_variable,
    direction_values,
    selectedDirectionIndex,
    selectedInstructionStep,
    selectedOutputIndex,
    selectedTableToRegistryIndex,
    newUuid,
    getOperationListRequest,
    getVariableListRequest,
    getOutputVariableListRequest,
    generateUuidRequest,
    updateAutonodeRequest,
    getAutonodeRequest,
    getAutonodeRegistriesRequest,
  };
});
