<script setup lang="js">
import { onMounted, ref, computed } from 'vue';
import { useServiceGroupStore } from '@/stores/serviceGroup.store';
import { useProjectStore } from '@/stores/project.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';

const serviceGroupStore = useServiceGroupStore();
const projectStore = useProjectStore();
const projectId = useRouteParams('projectId');
const {
  createServiceGroupRequest,
  serviceGroup,
  updateServiceGroupRequest
} = storeToRefs(serviceGroupStore);
const { project } = storeToRefs(projectStore);
const { closeModal, activeModal } = useModal();

const title = ref({});
const isUpdate = computed(() => activeModal.value === 'serviceGroupUpdateModal');

onMounted(() => {
  if (!project.value?.locales) {
    projectStore.getProject(projectId.value);
  }
  if (isUpdate.value && serviceGroup.value) {
    title.value = serviceGroup.value.title;
  }
});

function handleSubmit() {
  if (isUpdate.value) {
    serviceGroupStore.updateServiceGroup(projectId.value, serviceGroup.value.id, title.value).then(() => {
      closeModal();
    });
  } else {
    serviceGroupStore
      .createServiceGroup(
        projectId.value,
        title.value
      )
      .then(() => {
        closeModal();
      });
  }
}

function isErrorField(fieldName) {
  if (createServiceGroupRequest.value.error?.errors || updateServiceGroupRequest.value.error?.errors) {
    return createServiceGroupRequest.value.error.errors[fieldName] || !updateServiceGroupRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!createServiceGroupRequest.value.error?.errors || !updateServiceGroupRequest.value.error?.errors) {
    return createServiceGroupRequest.value.error || updateServiceGroupRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide
    @submit="handleSubmit"
    :disableButtons="createServiceGroupRequest.isLoading || updateServiceGroupRequest.isLoading"
  >
    <template v-slot:title> {{ isUpdate ? 'Редактировать группу' : 'Создать группу' }}</template>
    <template v-slot:body>
      <div class="mt-3" v-for="locale in project.locales" :key="locale.id">
        <label class="form-label">Наименование группы на {{ locale.name }}</label>
        <input
          type="text"
          v-model="title[locale.id]"
          class="form-control"
          :class="{ error: isErrorField('title') }"
        />
        <p v-if="isErrorField('title')" class="error-mesage">
          <span v-for="error in isErrorField('title')">{{ error }}</span>
        </p>
      </div>
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit> {{ isUpdate ? 'Редактировать' : 'Создать' }}</template>
  </ModalTemplateSide>
</template>
